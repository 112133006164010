<template>
  <div>
    <v-btn rounded outlined @click="openEntry()"
      ><v-icon small left>fal fa-pencil-alt</v-icon>Edit</v-btn
    >

    <!--Record Entry-->
    <v-dialog v-model="show4" persistent max-width="800">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Provider Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Name On Direct Deposit-->
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="d_directDepositName"
                    label="Name On Direct Deposit"
                    placeholder=" "
                    persistent-placeholder
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Transit Routing Number-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_bankRoutingNumber"
                    label="Transit Routing Number"
                    placeholder=" "
                    persistent-placeholder
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Account Number-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_bankAccountNumber"
                    label="Account Number"
                    placeholder=" "
                    persistent-placeholder
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";

import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "providersStore/getEntryField",
  mutationType: "providersStore/updateEntryField"
});

export default {
  name: "ProviderEdit",
  mixins: [mixin],
  computed: {
    ...mapState({
      providersStore: state => state.providersStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show4",
      "valid",
      "section",
      "mode",
      "id",

      "fk_countyID",
      "fk_ratingID",
      "d_nameLicense",
      "d_nameFederal",
      "d_licenseNumber",
      "d_taxpayerID",
      "d_website",
      "d_email",
      "d_facilityStreet1",
      "d_facilityStreet2",
      "d_facilityCity",
      "d_facilityState",
      "d_facilityPostalCode",
      "d_businessStreet1",
      "d_businessStreet2",
      "d_businessCity",
      "d_businessState",
      "d_businessPostalCode",
      "d_facilityPhone",
      "d_facilityFax",
      "d_businessPhone",
      "d_businessFax",
      "d_bankRoutingNumber",
      "d_bankAccountNumber",
      "d_mailingsAttendance",
      "d_mailingsPayment",
      "d_overageType",
      "d_referralID",
      "d_referralReason",
      "d_datePacketUpdate",
      "d_directDepositName",
      "d_specialRequests",
      "f_sameAddress",
      "f_overage",
      "f_onReferral",
      "f_type",
      "f_status",
      "date1"
    ])
  },
  data() {
    return {
      menu: false
    };
  },
  watch: {
    date1() {
      this.d_datePacketUpdate = this.$_formatDate(this.date1);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("providersStore/resetEntry");
    },

    async openEntry() {
      this.resetEntry();

      const provider = this.$store.getters["providersStore/getProvider"];

      await this.$store.dispatch("providersStore/entry", provider.uuid);

      this.mode = 2;
      this.show4 = true;
    },

    cancelEntry() {
      this.show4 = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["providersStore/getEntry"];
        await this.$store.dispatch("providersStore/update", entry);
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
